<template>
	<div id="userList">
		<div class="el-content mb12">
            <div class="kd-pacel">检索</div>
			<a-space>
				<a-select
					v-model:value="search.login_time"
					style="width: 200px"
					placeholder="登录时间排序"
				>
					<a-select-option value="1">正序</a-select-option>
					<a-select-option value="2">倒叙</a-select-option>
				</a-select>
				<a-input-search 
                    v-model:value="search.keyword" 
                    style="width: 300px" 
                    placeholder="姓名" 
                    enter-button 
                    @search="getUserList(1, info.limit)" 
                />
				<a-button type="primary" v-has="{ action: 'sass_agency_add' }" @click="showEditUser(0)">
					<i class="ri-add-line ri-top"></i>
					添加用户
				</a-button>
			</a-space>
		</div>

		<div class="el-content">
            <div class="kd-pacel">列表</div>
			<a-table
				:pagination="false"
				row-key="user_id"
				:data-source="info.list"
				:columns="[
					{ title: 'ID', dataIndex: 'user_id' },
					{ title: '手机号', dataIndex: 'mobile' },
					{ title: '用户名', dataIndex: 'name' },
					{ title: '应用', dataIndex: 'program_count' ,slots:{customRender:'program_count'}},
					{ title: '续费折扣', dataIndex: 'is_renewal_discount' ,slots:{customRender:'is_renewal_discount'}},
					{ title: '标签', dataIndex: 'tag', slots: { customRender: 'tag' } },
					{ title: '创建时间', dataIndex: 'create_time' },
					{ title: '最后登录时间', dataIndex: 'login_time',slots: { customRender: 'login_time' } },
					{ title: '状态', dataIndex: 'status', slots: { customRender: 'status' } },
					{ title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
				]"
			>
				<template #program_count="{ record }">
					<div><span class="f12">应用数量：</span>{{record.program_count}}</div>
					<div><span class="f12">购买数量：</span>{{record.pay_program_count}}</div>
				</template>
				<template #status="{ record }">
					<a-tag :color="record.status == 1 ? '#00CC66' : '#FF6633'">
						{{ record.status ? '正常' : '已禁用' }}
					</a-tag>
				</template>
				<template #is_renewal_discount="{ record }">
					<a-tooltip title="点击修改续费折扣状态">
						<a-tag 
							class="pt"
							@click="changeRenewDiscount(record)" 
							:color="record.is_renewal_discount ? '#00CC66' : '#999'"
						>
							{{ record.is_renewal_discount ? '有' : '无' }}
						</a-tag>
					</a-tooltip>
				</template>
				<template #tag="{ record }">
					<a-tag @click="getTagUser(1,10,item.id)" 
						color="#9999FF" 
						v-for="(item,index) in record.tags" 
						:key="index"
					>
						{{ item.title }}
					</a-tag>
					<a-tooltip title="设置标签">
						<a-tag @click="showTags(record)" style="background: #fff; border-style: dashed;cursor: pointer;">
							<i class="ri-add-line"></i>
						</a-tag>
					</a-tooltip>
				</template>
				<template #login_time="{ record }">
					<div v-if="record.login_time"><span class="f12">时间：</span>{{record.login_time}}</div>
					<div v-if="record.last_login_ip"><span class="f12">登录IP：</span>{{record.last_login_ip}}</div>
				</template>
				<template #action="{ record }">
					<router-link :to="{ path: '/admin/user/user_info', query: { user_id: record.user_id } }">
						<a-button v-has="{ action: '/admin/user/user_info' }" type="link" size="small">详情</a-button>
					</router-link>
					<a-button 
                        v-has="{ action: 'sass_user_stop' }" 
                        type="link" 
                        @click="deletUser(record)" 
                        size="small">{{ record.status == 1 ? '禁用' : '启用' }}
                    </a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="info.page"
					v-model:pageSize="info.limit"
					:total="info.count"
					@showSizeChange=" (p, e) => { getUserList(info.page, e); } "
					@change="e => getUserList(e, info.limit)"
				/>
			</div>
		</div>
		<a-modal title="添加编辑用户" 
            v-model:visible="userState.show" 
            width="700px"  
            @ok="saveUser" 
            @cancel="userState.show = false"
        >
			<a-form :label-col="{span:4}" :wrapper-col="{span:18}">
				<a-form-item label="昵称">
					<a-input v-model:value="userState.form.name"></a-input>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input v-model:value="userState.form.mobile"></a-input>
				</a-form-item>
				<a-form-item label="密码">
					<a-input v-model:value="userState.form.password"></a-input>
				</a-form-item>
				<a-form-item label="地址">
					<a-input v-model:value="userState.form.address"></a-input>
				</a-form-item>
				<a-form-item label="永久授权">
					<a-radio-group v-model:value="userState.form.is_permanent">
						<a-radio :value="1">开启</a-radio>
						<a-radio :value="0">关闭</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="续费折扣">
					<kd-switch 
                        :value="form.is_renewal_discount" 
                        @change="e=>userState.form.is_renewal_discount = e"
                    ></kd-switch>
				</a-form-item>
				<a-form-item label="授权到期时间">
					<a-date-picker v-model:value="userState.form.validity_time" format="YYYY-MM-DD"/>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal title="用户标签" v-model:visible="tagState.show" width="700px"  @ok="allocationTag">
			<div class="ul-tags">
				<a-tag class="ul-tags-li" 
					v-for="(item,index) in tagState.tags" 
					@click.stop="tagState.form.id = item.id"
					:color="tagState.form.id === item.id ? '#00CC66': (item.checked ? '#0099FF':'')" 
					:key="index"
				>
					{{item.title}}
					<i class="ri-close-line" 
						style="cursor: pointer;" 
						v-if="item.checked" 
						@click.stop="deleteUserTag(index)">
					</i>
				</a-tag>

				<a-input
					v-if="tagState.showInput"
					ref="inputRef"
					type="text"
					size="small"
					:style="{ width: '78px' }"
					v-model:value="tagState.form.title"
					@blur="addTags"
					@keyup.enter="addTags"
				/>
				<a-tag v-else @click="showInput" style="background: #fff; border-style: dashed">
					<i class="ri-add-line"></i>
					新增标签
				</a-tag>
			</div>
		</a-modal>

		<a-modal title="用户列表" v-model:visible="tagState.tagsUser" width="700px"  @ok="allocationTag">
			<a-table :pagination="false" row-key="user_id" :data-source="tagState.tagUser.list"
				:columns="[
					{ title: 'ID', dataIndex: 'user_id' },
					{ title: '头像', dataIndex: 'pic_url' },
					{ title: '用户名', dataIndex: 'name' },
				]"
			>
				<template #pic_url="{ record }">
					<a-avatar :size="32" :src="record.pic_url"></a-avatar>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="tagState.tagUser.page"
					v-model:pageSize="tagState.tagUser.limit"
					:total="tagState.tagUser.count"
					@showSizeChange=" (p, e) => { getTagUser(tagState.tagUser.page, e); } "
					@change="e => getTagUser(e, tagState.tagUser.limit)"
				/>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs,ref ,nextTick, toRaw} from 'vue';
import authModel from '@/api/saas/auth';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			search:{
				keyword:"",
				login_time:null,
			},
		})
		getUserList(1,state.info.limit)

		function getUserList(page,limit){
			authModel.getMerchantUserList(page,limit,state.search,res=>state.info = {limit,...res})
		}

        let userState = useEditUser({state,getUserList})
        let tagState = useSetTag({state,getUserList})

		return{
			...toRefs(state),
			getUserList,
            ...userState,
            ...tagState
		}
	}
}


//用户添加、更新信息操作
function useEditUser(obj){
    let userState = reactive({
        show:false,
        form:null
    })

    function showEditUser(){
        userState.form= {
            name: '',
            mobile: '',
            password: '',
            is_permanent: 0,
            validity_time: '',
            address: '',
            is_renewal_discount:0
        }
        userState.show = true
    }

    function saveUser(){
        authModel.addMerchantUser(toRaw(userState.form),()=>{
			obj.getUserList(obj.state.info.page,obj.state.info.limit)
			userState.show = false;
		})
    }

    function changeRenewDiscount(data){
         let param = {
            pic_url: data.pic_url,
            name: data.name,
            mobile: data.mobile,
            email: data.email,
            user_id: data.user_id,
            type: 1,
            rank: 3,
            scene: 'edit',
            is_renewal_discount:data.is_renewal_discount ==1 ? 0 : 1
        }
        authModel.addMerchantUser(param,()=>obj.getUserList(obj.state.info.page,obj.state.info.limit))
    }

    function deletUser(row){
        authModel.changeEmployeeStatus(row.user_id,row.status ? 0 :1,()=>{
            obj.getUserList(obj.state.info.page,obj.state.info.limit)
        })
    }

    return{ userState,showEditUser,saveUser,deletUser,changeRenewDiscount }
}


function useSetTag(obj){
    let tagState = reactive({
        show:false,
        inputShow:false,
        tagsUser:false,
        tags:[],
        form:{
            user_id:0,
            title:""
        },
        tagUser: {
            list: [],
            page: 1,
            count: 0,
            limit: 10,
            tag_id:0,
        },
    })
    const inputRef = ref();
    getUserTag()
    function getUserTag(){
        authModel.getUserTags(res=>tagState.tags = res)
    }

    function showTags(data){
        let arr = []
        if( data.tags && data.tags.length > 0 ){
            data.tags.forEach(item=>arr.push(item.id))
        }
        tagState.tags.forEach(item=>{
            item.checked = false
            if( arr.includes(item.id) ){
                item.checked = true
            }
        })
        tagState.form.user_id = data.user_id
        tagState.show = true
    }

    function showInput(){
        tagState.showInput = true;
        console.log('inputref',inputRef);
        nextTick(() => {
            inputRef.value.focus();
        });
    }

    function addTags(){
        if( !tool.returnMessage(tagState.form.title,"请输入标签")) return
        authModel.addUserTags(()=>{
            tagState.form.title = ""
            getUserTag()
            obj.getUserList(obj.state.info.page,obj.state.info.limit)
        },tagState.form.id,tagState.form.user_id,tagState.form.title)
    }

    function allocationTag(){
        if( !tool.returnMessage(tagState.form.id,"请选择标签")) return
        authModel.addUserTags(()=>{
            tagState.form.id=0
            tagState.show = false
            obj.getUserList(obj.state.info.page,obj.state.info.limit)
        },tagState.form.id,tagState.form.user_id,tagState.form.title)
        
    }

    const deleteUserTag = e=>{
        authModel.deleteUserTag(()=>{
            tagState.tags[e].checked = false
            obj.getUserList(obj.state.info.page,obj.state.info.limit)
        },tagState.tags[e].id,tagState.form.user_id)
    }

    function getTagUser(page,limit,tag_id=null){
        tagState.tagsUser = true
        if( tag_id ) tagState.tagUser.tag_id = tag_id
        authModel.getTagUser(page,limit,{id:tagState.tagUser.tag_id},res=>{
            tagState.tagUser.list = res
        })
    }

    return{ tagState ,showTags,showInput,inputRef,allocationTag,addTags,deleteUserTag,getTagUser }
}


</script>

<style lang="scss">
.ul-tags{
	&-li{
		cursor: pointer;
		margin-bottom: 8px;
	}
}
</style>
